<template>
  <div id="filing-order-header">
    <template v-if="loaded">
      <h2 class="company-name">
        {{ companyName }}
      </h2>
      <h3 class="product-name-in-jurisdiction">
        {{ productNameInJurisdiction }}
      </h3>
    </template>
    <ct-centered-spinner v-else />
  </div>
</template>
<script>

export default {
  name: 'FilingOrderHeader',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
  },
  props: {
    companyName: {
      type: String,
      default: '',
    },
    jurisdictionName: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    productNameInJurisdiction() {
      return `${this.productName} in ${this.jurisdictionName}`
    },
    productName() {
      return this.product.name
    },
  },
  async mounted() {
    this.loaded = true
  },
}
</script>
<style lang="scss" scoped>
// Palette
$black1: #231F20;

// Typography
// Typography: Mixin
@mixin typography($font-size: 1.0em, $font-weight: $ct-ui-font-weight-4, $color: $black1) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $black1;
}

// Typography: Font
h2 {
  @include typography(
    $font-size: 2.125em,
    $font-weight: $ct-ui-font-weight-7
  );
}

h3 {
  @include typography(
    $font-size: 1.5em,
    $font-weight: $ct-ui-font-weight-7
  );
}

// Normalize
* {
  padding: 0;
  margin: 0;
}

// Component
div#filing-order-header {
  padding: 1.0em 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
}
</style>

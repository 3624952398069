var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "filing-order-header" } },
    [
      _vm.loaded
        ? [
            _c("h2", { staticClass: "company-name" }, [
              _vm._v("\n      " + _vm._s(_vm.companyName) + "\n    "),
            ]),
            _c("h3", { staticClass: "product-name-in-jurisdiction" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.productNameInJurisdiction) + "\n    "
              ),
            ]),
          ]
        : _c("ct-centered-spinner"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }